














import Vue from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import ActionButton, { ActionItem } from '@/components/molecules/ActionButton/ActionButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UserDetailTemplate from '@/components/templates/UserDetailTemplate/UserDetailTemplate.vue';
import * as Config from '@/config';
import { User} from '@/types/alligate';

export interface DataType {
  // Props
  page: number;
  loading: boolean;
  tempGroupPassages: any;
  cardItems: any;
  phoneItems: any;
  errorMessageLogs: string;
  listphones: any;
  listGatePassages: any;
  userData: any;
  goBack: string;

}

export default Vue.extend({
  name: 'UserDetailPage',
  components: {
    Breadcrumbs,
    SuccessButton,
    ActionButton,
    UserDetailTemplate,
  },
  mounted() {
    console.log('A');
    console.log('this.$route.query', this.$route.query);
    const userId = this.$route.query.userId;
    if (userId !== null && userId !== undefined) {
      this.getInFoUser(userId);
      this.getInforGroup(1, userId);
      this.getPcode(1, userId);
      this.getPhone(1, userId);
      this.getInforGatePassage(1, userId);
    }
    if (this.$route.query.goBack !== null && this.$route.query.goBack !== undefined) {
      this.goBack = this.$route.query.goBack.toString();
    }
    this.$emit('activeMenu', 'user');
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  data: (): DataType => ({
    // Props
    loading: false,
    page: 1,
    tempGroupPassages: [],
    cardItems: [],
    phoneItems: [],
    errorMessageLogs: '',
    listphones: [],
    listGatePassages: [],
    userData: {
      isAdmin: false,
      isMfaSms: false,
      userId: '',
      password: '',
      name: '',
      furigana: '',
      phoneNumber: '',
      option1: '',
      option2: '',
    },
    goBack: 'user',
  }),
  methods: {
    getInFoUser(userId: any) {
      this.loading = true;
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
        .then((response: any) => {
          this.userData = response.body.users[0];
          this.userData.phoneNumber = this.userData.phoneNumber.replace('+81', '0');
          console.log(this.userData);
          this.loading = false;
        })
        .then(null, (error: any) => {
          switch (error.status) {
            case 400:
              this.errorMessageLogs = Config.ERROR_400;
              break;
            case 403:
              if (error.message === 'Unauthorized') {
                this.errorMessageLogs = Config.ERROR_403;
              } else {
                this.errorMessageLogs = Config.ERROR_403_DENIED;
              }
              break;
            case 404:
              this.errorMessageLogs = Config.ERROR_404;
              break;
            case 500:
              this.errorMessageLogs = Config.ERROR_500;
              break;
            case 503:
              this.errorMessageLogs = Config.ERROR_503;
              break;
            default:
              this.errorMessageLogs = Config.ERROR_DEFAULT;
              break;
          }
          this.loading = false;
        });
    },
    getInforGroup(page: any, userId: any) {
      this.$http.get(Config.GROUPS_PASSAGE_API + '?userId=' + encodeURIComponent(userId) + '&embed=group')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.groupPassages.forEach((element: any) => {
              this.tempGroupPassages.push(element);
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getInforGroup(page + 1, userId);
            }
          } else {
            this.tempGroupPassages = [];
          }
        })
        .then( null, (err: any) => {
          console.log(err);
        });
    },
    getPcode(page: any, userId: any) {
      this.$http.get(Config.CARD_LIST_API + '?limit=1000&page=' + page +
      '&sortKey=desc:registered' + '&userId=' + encodeURIComponent(userId) + '&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0 && response.body.pCodes !== undefined) {
            response.body.pCodes.forEach((element: any) => {
              this.cardItems.push({text: element.pCodeId, value: element.pCodeId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getPcode(page + 1, userId);
            }
          } else {
            this.cardItems = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          }); // catch
    },
    getPhone(page: any, userId: any) {
      this.$http.get(Config.PHONES_LIST_API + '?limit=1000&page=' + page +
      '&sortKey=desc:registered' + '&userId=' + encodeURIComponent(userId) + '&isValid=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.phones.forEach((element: any) => {
              this.phoneItems.push({text: element.model, value: element.phoneId});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getPhone(page + 1, userId);
            }
          } else {
            this.phoneItems = [];
          }
        })
        .then( null, (err: any) => {
          console.log('err: ', err);
          }); // catch
    },
    getInforGatePassage(page: any, userId: any) {
      this.$http.get(Config.GATES_PASSAGE_API + '?userId=' + encodeURIComponent(userId) + '&embed=gate' + '&limit=1000&page=' + page + '&isExceptOptionalGate=true')
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            response.body.passages.forEach((element: any) => {
              this.listGatePassages.push({gateId: element.gateId,
              notAfter: element.notAfter, notBefore: element.notBefore,
              gateName: element.gate !== undefined ? element.gate.name : ''});
            });
            if (response.body.maxRecords > response.body.endRecord) {
              this.getInforGatePassage(page + 1, userId);
            }
          } else {
            this.listGatePassages = [];
          }
        })
        .then(null, (error) => {
          console.log(error);
        });
    },
  },
});
