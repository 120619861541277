var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-users-template" },
    [
      _c(
        "v-container",
        { staticClass: "container-users" },
        [
          _c("user-detail-table", {
            attrs: {
              listphones: _vm.listphones,
              listGatePassages: _vm.listGatePassages,
              tempGroupPassages: _vm.tempGroupPassages,
              cardItems: _vm.cardItems,
              phoneItems: _vm.phoneItems,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs,
              skillOptions: _vm.skillOptions,
              user: _vm.userData
            }
          }),
          _c(
            "v-row",
            { staticClass: "btn-round mb-10", attrs: { justify: "end" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn-primary",
                  attrs: { outlined: "", disabled: !_vm.userData.userId },
                  on: {
                    click: function($event) {
                      return _vm.goUserEdit(_vm.userData.userId)
                    }
                  }
                },
                [_vm._v(" 変更 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }