












































































import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import DisplayOption, { DisplayOptionItem } from '@/components/molecules/DisplayOption/DisplayOption.vue';
import Pagination from '@/components/molecules/Pagination/Pagination.vue';
import Searchbox, { SearchTargetItem } from '@/components/molecules/Searchbox/Searchbox.vue';
import SelectPerPage from '@/components/molecules/SelectPerPage/SelectPerPage.vue';
import ShowPerPage from '@/components/molecules/ShowPerPage/ShowPerPage.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';

import { User, Group, RelationGateGroup, UnlockGate } from '@/types/alligate';
import { DataTableHeader } from 'vuetify';
import * as Config from '@/config';
export interface DataType {
  searchTargetItems: SearchTargetItem[];
  selectedDisplayOption: string[];
  selectedItem: User[];
  searchItem: [];
  sortBy: any;
  selectedGroupIds: string[];
}

export default Vue.extend({
  name: 'UserTable',
  components: {
    CancelButton,
    DisplayOption,
    Pagination,
    Searchbox,
    SelectPerPage,
    ShowPerPage,
    SuccessButton,
    TextLink,
    InfoItem,
    InputForm,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    listGatePassages: {
      default: () => [],
    },
    skillOptions: {
      default: () => [],
    },
    listphones: {
      type: Array,
      default: () => [],
    },
    tempGroupPassages: {
      default: () => [],
    },
    cardItems: {
      default: () => [],
    },
    user: {
      default: () => [],
    },
    phoneItems: {
      default: () => [],
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    searchTargetItems: [
      { label: 'ユーザー名', value: 'name' },
      { label: 'メールアドレス', value: 'email' },
    ],
    selectedDisplayOption: [
      'name',
      'email',
    ],
    selectedItem: [],
    searchItem: [],
    selectedGroupIds: [],
    sortBy: 'email',
  }),
  computed: {
    headerGateTable: {
      get(): DataTableHeader[] {
        const ret: DataTableHeader[] = [
          {
            text: 'ゲート名',
            align: 'start',
            sortable: false,
            value: 'gateId',
          },
          {
            text: '時間',
            align: 'start',
            sortable: false,
            value: 'time',
          },
        ];
        return ret;
      },
    },
  },
  methods: {
    goLogPage() {
      this.$router.push({ path: Config.LOG, query: {tabId: 'user'}});
    },
    convertCommaJP(data: any) {
      const array: any[] = [];
      for (const item of data) {
        if (!array.includes(item.text)) {
          array.push(item.text);
        }
      }
      const dataArray =  array.join();
      return dataArray.replaceAll(',', '、');
    },
    clickGroup(userId: any, ID: any) {
      this.$router.push({ path: Config.GROUP_DETAIL, query: {groupId: ID, goBack: 'user/detail?userId=' + userId}});
    },
    formatDateTime(date: Date): string {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hour = date.getHours().toString().padStart(2, '0');
      const min = date.getMinutes().toString().padStart(2, '0');
      return year + '/' + month + '/' + day + ' ' + hour + ':' + min;
    },
  },
});
