























declare global {
  interface Window {
    WebAppInterface: any;
  }
}
import Vue from 'vue';
import UserAddTemplate from '@/components/templates/UserAddTemplate/UserAddTemplate.vue';
import * as Config from '@/config';
import moment from 'moment';
import store from './../../../store';

export interface DataType {
  // Props
  isEdit: boolean;
  isConfirm: number;
  userData: any;
  gateGroupOptions: any;
  skillOptions: any;
  errorMessageLogs: string;
  successMessageLogs: string;
  loading: boolean;
  isSendEmail: boolean;
  // Page
  pageGroup: number;
  groupsAddFail: any;
  groupItemsOrigin: any;
  goBack: string;
  dataUpdateUser: any;
  isWaitingPushToken: boolean;
}
interface PushUser {
  deviceType: string;
  phoneId: string;
  deviceToken: string;
}
interface User {
  isAdmin?: boolean;
  isMfaSms?: boolean;
  userId?: string;
  password?: string;
  name: string;
  furigana?: string;
  phoneNumber?: string;
  option1: string;
  option2?: string;
  email?: string;
  emailConfirm?: string;
  isPhoneAdmin?: boolean;
  isSendEmail?: boolean;
  ownerId?: string;
  birthDay?: string;
  groupIds?: any;
  skillIds?: any;
  firstName?: any;
  lastName?: any;
  isPasswordLoginValid?: any;
  oldPassword?: any;
  authority?: any;
}
export default Vue.extend({
  name: 'UserAddPage',
  components: {
    UserAddTemplate,
  },
  mounted() {
    document.addEventListener('continueUpdateUser', this.continueUpdateUser);
  },
  created() {
    if (this.$route.path === '/user/edit') {
      this.isEdit = true;
      console.log('this.$route.query', this.$route.query);
      if (this.$route.query.userId !== null && this.$route.query.userId !== undefined) {
        const userId = this.$route.query.userId;
        this.loading = true;
        this.getInFoUser(userId).then(() => {
          this.loading = false;
        });
      }
      if (this.$route.query.goBack !== undefined) {
        this.goBack = this.$route.query.goBack.toString();
      }
    }
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
  },
  data: (): DataType => ({
    // Props
    isEdit: false,
    isConfirm: 0,
    userData: {
      isAdmin: false,
      isMfaSms: false,
      userId: '',
      email: '',
      password: '',
      name: '',
      furigana: '',
      phoneNumber: '',
      option1: '',
      option2: '',
      company: '',
      position: '',
      detail: '',
      checkAgreement: false,
      checkInformation: false,
      emailConfirm: '',
      term: '',
      info: '',
      otherSkill: '',
      firstNameFurigana: '',
      lastNameFurigana: '',
      birthDay: '',
      firstName: '',
      lastName: '',
      groupIds: [],
      skillIds: [],
      isCSV: false,
      oldPassword: '',
      authority: '',
    },
    errorMessageLogs: '',
    successMessageLogs: '',
    // Page
    loading: false,
    isSendEmail: false,
    goBack: 'user',
    pageGroup: 1,
    groupsAddFail: [],
    groupItemsOrigin: [],
    gateGroupOptions: [
      {
        text : '地域創生',
        value : '1',
      },
      {
        text : 'ダイバーシティ',
        value : '2',
      },
      {
        text : '教育',
        value : '3',
      },
      {
        text : 'インフラ・エネルギー',
        value : '4',
      },
      {
        text : 'テクノロジー',
        value : '5',
      },
      {
        text : 'DX',
        value : '6',
      },
      {
        text : '金融',
        value : '7',
      },
      {
        text : 'フード',
        value : '8',
      },
      {
        text : 'ヘルス・メディカル',
        value : '9',
      },
      {
        text : 'ツーリズム＆モビリティ',
        value : '10',
      },
      {
        text : 'ライフスタイル',
        value : '11',
      },
      {
        text : 'アセット',
        value : '12',
      },
      {
        text : 'エンタメ',
        value : '13',
      },
      {
        text : 'スポーツ',
        value : '14',
      },
    ],
    skillOptions: [
      {
        text : 'IT/デジタル',
        value : '1',
      },
      {
        text : 'マーケティング',
        value : '2',
      },
      {
        text : '人材育成',
        value : '3',
      },
      {
        text : '経理',
        value : '4',
      },
      {
        text : '法律',
        value : '5',
      },
      {
        text : 'デザイン・アート',
        value : '6',
      },
      {
        text : 'エンタメ',
        value : '7',
      },
      {
        text : '営業',
        value : '8',
      },
      {
        text : '事業開発',
        value : '9',
      },
      {
        text : '接客サービス',
        value : '10',
      },
      {
        text : '建築/土木関係/研究開発',
        value : '11',
      },
    ],
    dataUpdateUser: {},
    isWaitingPushToken: false,
  }),
  computed: {},
  methods: {
    backUserList() {
      this.$router.push({ path: '/' + this.goBack});
    },
    changeIsSendEmail(value: any) {
      this.isSendEmail = value;
    },
    clickConfirm() {
      this.errorMessageLogs = '';
      this.isConfirm = 1;
    },
    clickUserForm() {
      this.errorMessageLogs = '';
      this.isConfirm = 0;
    },
    async clickSaveUser(data: any) {
      let result;
      this.loading = true;
      this.errorMessageLogs = '';
      this.successMessageLogs = '';
      result = await this.postUser(data);
      if (result) {
        this.loading = false;
        this.isConfirm = 2;
      } else {
        this.loading = false;
        this.isConfirm = 1;
      }
    },
    postUser(data: any) {
      console.log(data);
      return new Promise((resolve, reject) => {
        this.loading = true;
        let user: User;
        const isSendEmail = data.userInfo.isSendEmail ? '1' : '0';
        const isCSV = data.userInfo.isCSV ? '1' : '0';
        const option = '<' + data.userInfo.birthDay + '><' + data.userInfo.company + '><' + data.userInfo.groupIds + '><'
        + data.userInfo.detail + '><' + data.userInfo.skillIds + '><' + data.userInfo.otherSkill + '><' + isSendEmail + '><' +
        isCSV + '>';
        const phoneSelft = data.userInfo.phoneNumber.substring(1);
        user = {
          ownerId: 'quintbridge',
          userId: data.userInfo.email,
          password: data.userInfo.password,
          name: data.userInfo.firstName + '　' + data.userInfo.lastName,
          furigana: data.userInfo.firstNameFurigana + '　' + data.userInfo.lastNameFurigana,
          email: data.userInfo.email,
          phoneNumber: '+81' + phoneSelft,
          option1: option,
          isSendEmail: true,
          isMfaSms: data.userInfo.isMfaSms,
        };
        this.$http.post(Config.PUBLIC_USER_API, JSON.stringify(user))
          .then((response: any) => {
            if (response.body.message === 'Success') {
              this.errorMessageLogs = '';
              this.successMessageLogs = 'ユーザーが追加されました';
              // Update isMfaSms
              const encodedUserId = encodeURIComponent(encodeURIComponent(data.userInfo.email));
              this.$http.put(Config.USERS_LIST_API + '/' + encodedUserId, JSON.stringify(user))
                .then((res: any) => {
                  resolve(true);
                })
                .then(null, (error) => {
                  resolve(true);
                });
              this.userData = {
                userId: data.userInfo.email,
                ownerId: 'quintbridge',
                password: '',
                name: data.userInfo.name,
                phoneNumber: '',
                option1: '',
                isSendEmail: false,
              };
            } else {
              this.successMessageLogs = '';
              this.errorMessageLogs = Config.ADD_USER_API_ERROR;
              resolve(false);
            }
          })
          .then( null, (error: any) => {
            console.log('error', error);
            this.successMessageLogs = '';
            this.errorMessageLogs = '';
            switch (error.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                this.errorMessageLogs = Config.ERROR_403;
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
        });
      });
    },
    getInFoUser(userId: any) {
      return new Promise((resolve, reject) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            const userData =  response.body.users[0];
            const option = userData.option1.replaceAll('<', '').split('>');
            const name = userData.name.split('　');
            const furigana = userData.furigana.split('　');
            userData.phoneNumber = userData.phoneNumber.replace('+81', '0');
            userData.emailConfirm = userData.email;
            userData.birthDay = option[0] !== undefined && option[0] !== 'NaN-NaN-NaN' ? option[0] : '';
            userData.company = option[1] !== undefined ? option[1] : '';
            userData.groupIds = option[2] !== undefined ? option[2] : '';
            userData.detail = option[3] !== undefined ? option[3] : '';
            userData.skillIds = option[4] !== undefined ? option[4] : '';
            userData.otherSkill = option[5] !== undefined ? option[5] : '';
            userData.isSendEmail = option[6] !== undefined && option[6] === '1' ? true : false;
            userData.isCSV = option[7] !== undefined && option[7] === '1' ? true : false;
            userData.ownerId = response.body.users[0].owner !== undefined ? response.body.users[0].owner.ownerId : '';
            userData.firstName = name[0] !== undefined ? name[0] : '';
            userData.lastName = name[1] !== undefined ? name[1] : '';
            userData.firstNameFurigana = furigana[0] !== undefined ? furigana[0] : '';
            userData.lastNameFurigana = furigana[1] !== undefined ? furigana[1] : '';
            userData.authority = option[8] !== undefined ? option[8] : '';
            this.userData = userData;
            resolve(null);
          })
          .then(null, (error: any) => {
            resolve(null);
            switch (error.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
        });
      });
    },
    async clickUpdateUser(data: any) {
      this.loading = true;
      this.successMessageLogs = '';
      this.errorMessageLogs = '';
      this.groupsAddFail = [];
      this.dataUpdateUser = data;
      console.log(data);
      let result;
      // result = await this.updateUser(data);
      if (data.userInfo.password !== undefined &&
      data.userInfo.password !== null && data.userInfo.password !== '') {
        let resultChange;
        resultChange = await this.changePassword(data);
        if (resultChange) {
          if (window.WebAppInterface !== undefined) {
            const isSendEmail = data.userInfo.isSendEmail ? '1' : '0';
            const birthDay = data.userInfo.birthDay !== undefined ? data.userInfo.birthDay : '';
            const company = data.userInfo.company !== undefined ? data.userInfo.company : '';
            const groupIds = data.userInfo.groupIds !== undefined ? data.userInfo.groupIds : '';
            const detail = data.userInfo.detail !== undefined ? data.userInfo.detail : '';
            const skillIds = data.userInfo.skillIds !== undefined ? data.userInfo.skillIds : '';
            const otherSkill = data.userInfo.otherSkill !== undefined ? data.userInfo.otherSkill : '';
            const isCSV = data.userInfo.isCSV ? '1' : '0';
            const authority = data.userInfo.authority !== undefined ? data.userInfo.authority : '';
            const option1 = '<' + birthDay + '><' + company + '><'
              + groupIds + '><' + detail + '><' + skillIds + '><' + otherSkill + '><'
              + isSendEmail + '><' + isCSV + '><' + authority + '>';
            const request = { userId: data.userInfo.userId, option: option1, option2: data.userInfo.option2};
            this.isWaitingPushToken = true;
            window.WebAppInterface.requestMobileRegisterToken(JSON.stringify(request));
            return;
          }

          const option2Update = await this.updateOption2Data(data.userInfo.userId) as string;
          result = await this.updateUser(data, option2Update);
        }
      } else {
        if (window.WebAppInterface !== undefined) {
          const isSendEmail = data.userInfo.isSendEmail ? '1' : '0';
          const birthDay = data.userInfo.birthDay !== undefined ? data.userInfo.birthDay : '';
          const company = data.userInfo.company !== undefined ? data.userInfo.company : '';
          const groupIds = data.userInfo.groupIds !== undefined ? data.userInfo.groupIds : '';
          const detail = data.userInfo.detail !== undefined ? data.userInfo.detail : '';
          const skillIds = data.userInfo.skillIds !== undefined ? data.userInfo.skillIds : '';
          const otherSkill = data.userInfo.otherSkill !== undefined ? data.userInfo.otherSkill : '';
          const isCSV = data.userInfo.isCSV ? '1' : '0';
          const authority = data.userInfo.authority !== undefined ? data.userInfo.authority : '';
          const option1 = '<' + birthDay + '><' + company + '><'
            + groupIds + '><' + detail + '><' + skillIds + '><' + otherSkill + '><'
            + isSendEmail + '><' + isCSV + '><' + authority + '>';
          const request = { userId: data.userInfo.userId, option: option1, option2: data.userInfo.option2};
          this.isWaitingPushToken = true;
          window.WebAppInterface.requestMobileRegisterToken(JSON.stringify(request));
          return;
        }
        const option2Update = await this.updateOption2Data(data.userInfo.userId) as string;
        result = await this.updateUser(data, option2Update);
      }
      if (result) {
        this.loading = false;
        this.isConfirm = 2;
      } else {
        this.loading = false;
        this.isConfirm = 1;
      }
    },
    async continueUpdateUser() {
      if (this.isWaitingPushToken) {
        const option2Update = await this.updateOption2Data(this.dataUpdateUser.userInfo.userId) as string;
        const result = await this.updateUser(this.dataUpdateUser, option2Update);
        this.isWaitingPushToken = false;
        if (result) {
          this.loading = false;
          this.isConfirm = 2;
        } else {
          this.loading = false;
          this.isConfirm = 1;
        }
      }
    },
    changePassword(data: any) {
      return new Promise((resolve, reject) => {
        const credentials = {
          oldPassword: data.userInfo.oldPassword,
          newPassword: data.userInfo.password,
        };
        this.$http.post(Config.USER_CHANGE_PASSWORD_API, JSON.stringify(credentials))
          .then((response: any) => {
            resolve(true);
            })
          .then(null, (error) => {
            resolve(false);
            switch (error.status) {
              case 400:
                if (error.message === 'Bad request') {
                  this.errorMessageLogs = Config.ERROR_400;
                } else {
                  this.errorMessageLogs = Config.ERROR_400_INCORRECT_USER;
                }
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
          });
      });
    },
    updateUser(data: any, option2: string) {
      console.log('option2Update', option2);
      return new Promise((resolve, reject) => {
        const encodedUserId = encodeURIComponent(encodeURIComponent(data.userInfo.userId));
        const isSendEmail = data.userInfo.isSendEmail ? '1' : '0';
        const birthDay = data.userInfo.birthDay !== undefined ? data.userInfo.birthDay : '';
        const company = data.userInfo.company !== undefined ? data.userInfo.company : '';
        const groupIds = data.userInfo.groupIds !== undefined ? data.userInfo.groupIds : '';
        const detail = data.userInfo.detail !== undefined ? data.userInfo.detail : '';
        const skillIds = data.userInfo.skillIds !== undefined ? data.userInfo.skillIds : '';
        const otherSkill = data.userInfo.otherSkill !== undefined ? data.userInfo.otherSkill : '';
        const isCSV = data.userInfo.isCSV ? '1' : '0';
        const authority = data.userInfo.authority !== undefined ? data.userInfo.authority : '';
        const option = '<' + birthDay + '><' + company + '><'
        + groupIds + '><' + detail + '><' + skillIds + '><' + otherSkill + '><' + isSendEmail + '><' + isCSV + '><'
        + authority + '>';
        const phoneSelft = data.userInfo.phoneNumber.substring(1);
        let user: User;
        user = {
          ownerId: data.userInfo.owner.ownerId,
          userId: data.userInfo.userId,
          name: data.userInfo.firstName + '　' + data.userInfo.lastName,
          furigana: data.userInfo.firstNameFurigana + '　' + data.userInfo.lastNameFurigana,
          email: data.userInfo.email,
          phoneNumber: '+81' + phoneSelft,
          option1: option,
          isSendEmail: false,
          isMfaSms: data.userInfo.isMfaSms,
        };

        if (option2 !== undefined && option2 !== null && option2 !== '') {
          user.option2 = option2;
        }
        this.$http.put(Config.USERS_LIST_API + '/' + encodedUserId, JSON.stringify(user))
          .then((response: any) => {
            this.errorMessageLogs = '';
            this.successMessageLogs = Config.EDIT_USER_API_SUCCESS;
            resolve(true);
          })
          .then(null, (error) => {
            this.successMessageLogs = '';
            switch (error.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (error.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = Config.ERROR_DEFAULT;
                break;
            }
            resolve(false);
          });
      });
    },
    updateOption2Data(userId: string) {
      return new Promise(async (resolve) => {
        const phoneList = await this.getPhoneList(userId) as string[];
        console.log('phoneList', phoneList);
        let option2Update = '';
        if (phoneList.length !== 0) {
          const option2 = await this.getGroupTokenOfUser(userId) as string;
          if (option2 !== undefined && option2 !== null && option2 !== '') {
            const option2Split = option2.replaceAll('<', '').split('>');
            const entry = option2Split[0] !== undefined ? option2Split[0] : '';
            const corporate = option2Split[1] !== undefined ? option2Split[1] : '0';
            const information = option2Split[2] !== undefined ? option2Split[2] : '0';

            console.log('option2', option2Split);
            const pushUserList: PushUser[] = [];
            if (option2Split[3] !== undefined) {
              const pushGroup = option2Split[3].split(';');
              for (const item of pushGroup) {
                const data = item.split(',');
                if (data.length === 3) {
                  const push: PushUser = {
                    deviceType: data[0],
                    phoneId: data[1],
                    deviceToken: data[2],
                  };
                  pushUserList.push(push);
                }
              }
            }
            console.log('pushUserList', pushUserList);
            const pushUserValid: PushUser[] = [];
            for (const item of pushUserList) {
              if (phoneList.includes(item.phoneId)) {
                console.log('phoneId is valid', item.phoneId);
                pushUserValid.push(item);
              }
            }
            console.log('pushUserValid', pushUserValid);
            const groupToken = pushUserValid.flatMap((e) => {
              return e.deviceType + ',' + e.phoneId + ',' + e.deviceToken;
            }).join(';');
            console.log('groupUpdate', groupToken);
            option2Update = '<' + entry + '><' + corporate + '><' + information + '><' + groupToken + '>';
          }
        }
        resolve(option2Update);
      });
    },
    getPhoneList(userId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.PHONES_LIST_API + '?limit=1000&userId=' + encodeURIComponent(userId) + '&isValid=true')
        .then((response: any) => {
          const phoneList: string[] = [];
          response.body.phones.forEach((element: any) => {
              phoneList.push(element.phoneId);
            });
          resolve(phoneList);
        }, () => {
          resolve([]);
        });
      });
    },
    getGroupTokenOfUser(userId: string) {
      return new Promise((resolve) => {
        this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(userId))
          .then((response: any) => {
            resolve(response.body.users[0].option2);
          }, () => {
            resolve('');
          });
      });
    },
    formatTimestamp(t: string) {
      return moment(t).unix();
    },
  },
});
