var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UserDetailTemplate", {
    attrs: {
      loading: _vm.loading,
      tempGroupPassages: _vm.tempGroupPassages,
      cardItems: _vm.cardItems,
      phoneItems: _vm.phoneItems,
      errorMessageLogs: _vm.errorMessageLogs,
      listphones: _vm.listphones,
      listGatePassages: _vm.listGatePassages,
      skillOptions: _vm.skillOptions,
      userData: _vm.userData,
      goBack: _vm.goBack
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }