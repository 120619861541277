var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UserAddTemplate", {
    attrs: {
      isEdit: _vm.isEdit,
      isConfirm: _vm.isConfirm,
      loading: _vm.loading,
      groupsAddFail: _vm.groupsAddFail,
      gateGroupOptions: _vm.gateGroupOptions,
      skillOptions: _vm.skillOptions,
      userData: _vm.userData,
      isSendEmail: _vm.isSendEmail,
      goBack: _vm.goBack,
      errorMessageLogs: _vm.errorMessageLogs,
      successMessageLogs: _vm.successMessageLogs
    },
    on: {
      changeIsSendEmail: _vm.changeIsSendEmail,
      clickSaveUser: _vm.clickSaveUser,
      clickConfirm: _vm.clickConfirm,
      clickUpdateUser: _vm.clickUpdateUser,
      clickUserForm: _vm.clickUserForm,
      backUserList: _vm.backUserList
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }