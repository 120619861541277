































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































declare global {
  interface Window {
    WebAppInterface: any;
    webkit: any;
  }
}

import Vue, { PropType } from 'vue';
import CancelButton from '@/components/molecules/CancelButton/CancelButton.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import InfoItem from '@/components/molecules/InfoItem/InfoItem.vue';
import DatePicker from '@/components/molecules/DatePicker/DatePicker.vue';
import InputForm from '@/components/molecules/InputForm/InputForm.vue';
import { DataTableHeader } from 'vuetify';
import TextLink from '@/components/molecules/TextLink/TextLink.vue';
import moment from 'moment';
import * as Config from '@/config';

// import { User} from '@/types/alligate';
export interface DataType {
  step: number;
  pCodeIdValue: string;
  model: any;
  detailValue: string;
  typeValue: string;
  userIdValue: string;
  dataTime: any;
  // userInfo: User;
  realtionsGateList: any;
  timeStartGate: string;
  timeEndGate: string;
  dateStartGate: string;
  dateEndGate: string;
  itemGate: any;
  isClickExpandArea: boolean;
  checkSMS: boolean;
  showModal: boolean;
  showConfirm: boolean;
  autoselectMenu: boolean;
  rulesValidate: any;
  authorityItems: any;
  type: any;
  addItem: any;
  sameUser: boolean;
  searchInput: string;
  errorAddListGate: string;
  errorGate: string;
  errorTime: string;
  update: boolean;
  isDisableTerm: boolean;
  isDisableMemberAgreement: boolean;
  isDisableInformation: boolean;
  dateS: string;
  dateStart: boolean;
  groupValue: any;
  skillValue: any;
  yearItem: any;
  monthItem: any;
  dayItem: any;
  dataYears: any;
  dataMonths: any;
  dataDays: any;
  isMobibleApp: boolean;
}

interface User {
  userId: string;
  password: string;
  name: string;
  furigana: string;
  email: string;
  phoneNumber: string;
  thumbnail: string;
  passCode: string;
  isMfaSms: boolean;
  isAdmin: boolean;
  isPhoneAdmin: boolean;
  shareKeyBeValid: boolean;
  isValid: boolean;
  option1: string;
  option2: string;
  isSendEmail: boolean;
  pCodeIds: any;
  isPasswordLoginValid: boolean;
}

export default Vue.extend({
  name: 'UserAddForm',
  components: {
    CancelButton,
    SuccessButton,
    InfoItem,
    InputForm,
    DatePicker,
    TextLink,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    isSendEmail: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isConfirm: {
      type: Number,
      default: 0,
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    gateGroupOptions: {
      type: Array,
      default: () => [],
    },
    skillOptions: {
      type: Array,
      default: () => [],
    },
    userInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    if (window.WebAppInterface !== undefined || window.webkit !== undefined) {
      this.isMobibleApp = true;
    }
    const today = new Date();
    const currentYear = today.getFullYear();
    for (let i = currentYear ; i >= 1900; i--) {
      this.dataYears.push({
        text : i,
        value: i,
      });
    }
    for (let i = 1 ; i <= 12; i++) {
      this.dataMonths.push({
        text : i,
        value: i,
      });
    }
  },
  mounted() {
    // this.realtionsGateList = this.listGatePassages;
  },
  computed: {
    headers: {
      get(): DataTableHeader[] {
        const headers: DataTableHeader[] = [];
        headers.push({
          text: 'ゲート名',
          align: 'start',
          sortable: true,
          width: '300px',
          value: 'gateName',
        });
        headers.push({
          text: '時間',
          align: 'start',
          sortable: true,
          value: 'time',
        });
        headers.push({
          text: '',
          align: 'start',
          sortable: true,
          value: 'edit',
        });
        return headers;
      },
      set(): void {
        return;
      },
    },
    icon() {
      if (Object.keys(this.groupValue).length === Object.keys(this.gateGroupOptions).length) {
        return 'mdi-close-box';
      } else if (Object.keys(this.groupValue).length > 0 &&
      Object.keys(this.groupValue).length !== Object.keys(this.gateGroupOptions).length) {
        return 'mdi-minus-box';
      } else {
        return 'mdi-checkbox-blank-outline';
      }
    },
    iconSkill() {
      if (Object.keys(this.skillValue).length === Object.keys(this.skillOptions).length) {
        return 'mdi-close-box';
      } else if (Object.keys(this.skillValue).length > 0 &&
      Object.keys(this.skillValue).length !== Object.keys(this.skillOptions).length) {
        return 'mdi-minus-box';
      } else {
        return 'mdi-checkbox-blank-outline';
      }
    },
  },
  data: (): DataType => ({
    yearItem: {},
    monthItem: {},
    dayItem: {},
    dataYears: [],
    dataMonths: [],
    dataDays: [],
    dateS: '',
    dateStart: false,
    step: 0,
    isDisableTerm: true,
    isDisableMemberAgreement: true,
    isDisableInformation: true,
    addItem: {
      index: 0,
      item: '',
    },
    showModal: false,
    showConfirm: false,
    autoselectMenu: false,
    type: '0',
    model: [],
    errorAddListGate: '',
    errorGate: '',
    errorTime: '',
    pCodeIdValue: '',
    detailValue: '',
    typeValue: '',
    userIdValue: '',
    searchInput: '',
    realtionsGateList: [],
    timeStartGate: '',
    timeEndGate: '',
    dateStartGate: '',
    dateEndGate: '',
    itemGate: [],
    groupValue: [],
    skillValue: [],
    isClickExpandArea: false,
    checkSMS: false,
    update: true,
    sameUser: false,
    isMobibleApp: false,
    rulesValidate: {
      requiredYear: (data: any, isEdit: boolean) => {
        if ((data.value === null || data.value === undefined || data.value === '' || data.value < 0)
        && isEdit === false) {
          return '年は必須です。';
        } else {
          return true;
        }
      },
      requiredMonth: (data: any, isEdit: boolean) => {
        if ((data.value === null || data.value === undefined || data.value === '' || data.value < 0)
        && isEdit === false) {
          return data.value > 0 || '月は必須です。';
        } else {
          return true;
        }
      },
      requiredDay: (data: any, isEdit: boolean) => {
        if ((data.value === null || data.value === undefined || data.value === '' || data.value < 0)
        && isEdit === false) {
          return data.value > 0 || '日は必須です。';
        } else {
          return true;
        }
      },
      requiredName: (value: string, isEdit: boolean) => {
        if ((value === null || value === undefined || value === '') && isEdit === false) {
          return '氏名は必須です。';
        } else {
          return true;
        }
      },
      requiredFirstName: (value: string, isEdit: boolean) => {
        if ((value === null || value === undefined || value === '') && isEdit === false) {
          return '姓は必須です。';
        } else {
          return true;
        }
      },
      requiredLastName: (value: string, isEdit: boolean) => {
        if ((value === null || value === undefined || value === '') && isEdit === false) {
          return '名は必須です。';
        } else {
          return true;
        }
      },
      validateFirstName: (first: string, last: string, isEdit: boolean) => {
        if (isEdit === false) {
          if (first !== null && first !== undefined && first !== '') {
            const check = first.length + last.length;
            return check <= 30 || '氏名は30文字以内で入力してください。';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      validateLastName: (last: string, first: string, isEdit: boolean) => {
        if (isEdit === false) {
          if (last !== null && last !== undefined && last !== '') {
            const check = first.length + last.length;
            return check <= 30 || '氏名は30文字以内で入力してください。';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      validateFirstNameFurigana: (first: string, last: string, isEdit: boolean) => {
        if (isEdit === false) {
          if (first !== null && first !== undefined && first !== '') {
            const regex = /[\u30A0-\u30FF]/g;
            const input = first;
            if (regex.test(input)) {
              const check = first.length + last.length;
              return check <= 64 || 'フリガナは64文字以内で入力してください。';
            } else {
              return 'フリガナは64文字以内で入力してください。';
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      validateLastNameFurigana: (last: string, first: string, isEdit: boolean) => {
        if (isEdit === false) {
          if (last !== null && last !== undefined && last !== '') {
            const regex = /[\u30A0-\u30FF]/g;
            const input = last;
            if (regex.test(input)) {
              const check = first.length + last.length;
              return check <= 64 || 'フリガナは64文字以内で入力してください。';
            } else {
              return 'フリガナは64文字以内で入力してください。';
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      requiredCompany: (value: string, isEdit: boolean) => {
        if ((value === null || value === undefined || value === '') && isEdit === false) {
          return '所属は必須です。';
        } else {
          return true;
        }
      },
      validateCompany: (value: string, isEdit: boolean) => {
        if (isEdit === false) {
          if (value !== null && value !== undefined && value !== '') {
            return value.length <= 30 || '所属は30文字以内で入力してください。';
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      validatePosition: (value: string) => {
      if (value !== null && value !== undefined && value !== '') {
          return value.length <= 30 || '役職は30文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateDetails: (value: string) => {
      if (value !== null && value !== undefined && value !== '') {
        if (value.length > 50) {
          return '興味関心 詳細は50文字以内で入力してください。';
        } else {
          const pattern = value.match(/[,<>]/g);
          if (pattern) {
            return '「,」「<」「>」は、使用できません';
          } else {
            return true;
          }
        }
        } else {
          return true;
        }
      },
      validateOtherSkill: (value: string) => {
      if (value !== null && value !== undefined && value !== '') {
          if (value.length > 50) {
            return '保有スキル その他は50文字以内で入力してください。';
          } else {
          const pattern = value.match(/[,<>]/g);
          if (pattern) {
            return '「,」「<」「>」は、使用できません';
          } else {
            return true;
          }
        }
          // return value.length <= 50 || '保有スキル その他は50文字以内で入力してください。';
        } else {
          return true;
        }
      },
      validateGroup: (data: any) => {
      if (data === null || data === undefined || data === '' || data.length <= 0) {
          return '興味関心カテゴリは必須です。';
        } else {
          return true;
        }
      },
      requiredEmail: (value: string, isEdit: boolean) => {
        if ((value === null || value === undefined || value === '') && isEdit === false) {
          return 'メールアドレスは必須です。';
        } else {
          return true;
        }
      },
      validateEmail: (email: string, isEdit: boolean) => {
        if (isEdit === false) {
          if (email !== null && email !== undefined && email !== '') {
            const pattern = email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/);
            if (pattern) {
              return true;
            } else {
              return '正しいメールアドレスを入力してください。';
            }
          } else {
            return true;
          }
        } else {
          return true;
        }
      },
      validateConfirmEmail(value: any, confirm: any, isEdit: any) {
        if (isEdit === false && value !== '' && confirm !== value) {
          return '「メールアドレス」と一致しません。';
        } else {
          return true;
        }
      },
      // requiredPassword: (pass: string) => !!pass || 'パスワードは必須です。',
      validateOldPassword: (pass: string, password: string, isEdit: boolean) => {
        if (isEdit) {
          if (pass !== null && pass !== undefined && pass !== '') {
            const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
            return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
          } else if (password !== null && password !== undefined && password !== '') {
            const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
            return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
          } else {
            return true;
          }
        } else {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
          return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
        }
      },
      validatePassword: (pass: string, isEdit: boolean) => {
        if (isEdit) {
          if (pass !== null && pass !== undefined && pass !== '') {
            const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
            return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
          } else {
            return true;
          }
        } else {
          const pattern = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*).{8,64}$/;
          return pattern.test(pass) || 'パスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
        }
      },
      requiredPhone: (value: string, isEdit: boolean) => {
        if ((value === null || value === undefined || value === '') && isEdit === false) {
          return '電話番号は必須です';
        } else {
          return true;
        }
      },
      validatePhone: (phone: string, isEdit: boolean) => {
        if (isEdit === false) {
          const pattern = /^[0][0-9]{10}$/;
          return pattern.test(phone) || '0から始まる-(ハイフン)無しの携帯電話番号を半角数字で11桁入力してください';
        } else {
          return true;
        }
      },
    },
    authorityItems: [
      { text: '一般ユーザー', value: 'user' },
      { text: '職員', value: 'staff' },
      { text: '管理者', value: 'admin' },
    ],
    dataTime: '',
  }),
  watch: {
    loading(value: any) {
      const data  = this.userInfo;
      if (this.isEdit === true && value === false) {
        this.groupValue = [];
        this.skillValue = [];
        if (data.birthDay !== '') {
          const birtDay = data.birthDay.split('-');
          this.yearItem = {text : parseInt(birtDay[0]), value: parseInt(birtDay[0])};
          this.monthItem = {text : parseInt(birtDay[1]), value: parseInt(birtDay[1])};
          this.dataDays = [{
            text : parseInt(birtDay[2]),
            value: parseInt(birtDay[2]),
          }];
          this.dayItem = {text : parseInt(birtDay[2]), value: parseInt(birtDay[2])};
        }
        if (data.groupIds !== '') {
          const groupIds = data.groupIds.split('、');
          this.gateGroupOptions.forEach((element: any, key: any) => {
            if (groupIds.includes(element.value)) {
              const index  = this.groupValue.map((e: { value: any; }) => e.value).indexOf(element.value);
              if (index < 0) {
                this.groupValue.push({text: element.text, value: element.value});
              }
            }
          });
          // for (const item of groupIds) {
          //   if (!this.groupValue.includes(item)) {
          //     this.groupValue.push({text: item, value: item});
          //   }
          // }
        }
        if (data.skillIds !== '') {
          const skillIds = data.skillIds.split('、');
          this.skillOptions.forEach((element: any, key: any) => {
            if (skillIds.includes(element.value)) {
              const index  = this.skillValue.map((e: { value: any; }) => e.value).indexOf(element.value);
              if (index < 0) {
                this.skillValue.push({text: element.text, value: element.value});
              }
            }
          });
        }
      }
    },
  },
  methods: {
    changeMonth(data: any) {
      this.dataDays = [];
      if (this.yearItem.value !== undefined && this.yearItem.value > 0
      && this.monthItem.value !== undefined && this.monthItem.value > 0) {
        const totalDayes = new Date(this.yearItem.value, this.monthItem.value, 0).getDate();
        for (let i = 1 ; i <= totalDayes; i++) {
        this.dataDays.push({
          text : i,
          value: i,
        });
      }
    }
    },
    toggle() {
        this.$nextTick(() => {
          if (Object.keys(this.groupValue).length === Object.keys(this.gateGroupOptions).length) {
            this.groupValue = [];
          } else {
            this.groupValue = this.gateGroupOptions.slice();
          }
        });
      },
    toggleSkill() {
        this.$nextTick(() => {
          if (Object.keys(this.skillValue).length === Object.keys(this.skillOptions).length) {
            this.skillValue = [];
          } else {
            this.skillValue = this.skillOptions.slice();
          }
        });
      },
    downLoad(value: any, name: string, type: string) {
      if (type === 'Agreement') {
        this.isDisableTerm = false;
      } else if (type === 'MemberAgreement') {
        this.isDisableMemberAgreement = false;
      }
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (!isSafari) {
        const a = document.createElement('a');
        a.download = name;
        a.href = value;
        a.target  = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        window.open(value, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
      }
    },
    DownloadFile(value: any, name: string, type: string) {
      if (type === 'Agreement') {
        this.isDisableTerm = false;
      } else if (type === 'MemberAgreement') {
        this.isDisableMemberAgreement = false;
      }
      const xhr = new XMLHttpRequest();
      xhr.open('GET', value, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status === 200) {
          const myBlob = this.response;
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(myBlob);
          link.download = name;
          link.click();
        }
      };
      xhr.send();
    },
    clickOpenLink() {
      this.isDisableInformation =  false;
    },
    PageLogin() {
      this.step = 0;
      if (this.isMobibleApp) {
        if (this.isEdit) {
          window.location.reload();
        } else {
          window.location.href = Config.WEBAPP_LOGIN_ENDPOINT;
        }
      } else {
        this.$router.push({ path: '/'});
      }
    },
    customSort(items: any, index: any, isDescending: any) {
      if (!index || !index.length || !isDescending || !isDescending.length) {
      return items;
      }
      console.log(items);
      items.sort((a: any, b: any) => {
        if (index[0] === 'gateName') {
          if (!isDescending[0]) {
              return (a.gate.text < b.gate.text) ? -1 : 1;
          } else {
              return (b.gate.text < a.gate.text) ? -1 : 1;
          }
        } else if (index[0] === 'time') {
          if (!isDescending[0]) {
              return (a.notAfter < b.notAfter) ? -1 : 1;
          } else {
              return (b.notAfter < a.notAfter) ? -1 : 1;
          }
        } else {
          if (!isDescending[0]) {
              return a[index] < b[index] ? -1 : 1;
            } else {
              return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    clickConfirm() {
      const result = (this.$refs.UserAddForm as Vue & { validate: () => boolean }).validate();
      if (result) {
        this.$emit('clickConfirm', {
          userInfo: this.userInfo,
        });
      }
    },
    clickSaveUser() {
      this.userInfo.birthDay = this.yearItem.value + '-' + this.monthItem.value + '-' + this.dayItem.value;
      this.userInfo.groupIds = this.convertStringJP(this.groupValue);
      this.userInfo.skillIds = this.convertStringJP(this.skillValue);
      this.$emit('clickSaveUser', {
        userInfo: this.userInfo,
      });
    },
    clickUpdateUser() {
      if (this.dayItem.value !== undefined && this.dayItem.value !== '' && this.dayItem.value !== null) {
        this.userInfo.birthDay = this.yearItem.value + '-' + this.monthItem.value + '-' + this.dayItem.value;
      } else {
        this.userInfo.birthDay = '';
      }
      this.userInfo.groupIds = this.convertStringJP(this.groupValue);
      this.userInfo.skillIds = this.convertStringJP(this.skillValue);
      this.$emit('clickUpdateUser', {
        userInfo: this.userInfo,
      });
    },
    isObject(val: any) {
      return val instanceof Object;
    },
    closeModal() {
      const pCodeIds = this.userInfo.pCodeIds;
      pCodeIds.splice(parseInt(this.addItem.index), 1);
      this.showModal = false;
    },
    convertString(data: any) {
      const array: any[] = [];
      for (const item of data) {
        if (!array.includes(item.value)) {
          array.push(item.value);
        }
      }
      return array.join();
    },
    convertStringJP(data: any) {
      const array: any[] = [];
      for (const item of data) {
        if (!array.includes(item.value)) {
          array.push(item.value);
        }
      }
      const dataArray =  array.join();
      return dataArray.replaceAll(',', '、');
    },
    convertStringNameJP(data: any) {
      const array: any[] = [];
      for (const item of data) {
        if (!array.includes(item.text)) {
          array.push(item.text);
        }
      }
      return array.join().replaceAll(',', '、');
    },
  },
});
