import Vue from 'vue';
import App from './App.vue';
import auth from './auth';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueResource from 'vue-resource';
import Amplify from 'aws-amplify';
Vue.config.productionTip = false;
switch (process.env.NODE_ENV) {
  case 'development':
    Amplify.configure({
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-northeast-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-northeast-1_w2jw5xbbK',
        // OPTIONAL - Amazon Cognito Web Client ID
        // userPoolWebClientId: '1qbh3udrhvpvopho8n7dbqi4k6'
        userPoolWebClientId: '6d29locmjqhf7ts6guq4uiddis',
      },
      oauth: {
        // 以下、全てCognitoに設定した情報
        domain: 'auth.dev.alligate.net',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://mgr.dev.alligate.net/',
        redirectSignOut: 'https://mgr.dev.alligate.net/',
        responseType: 'code',
      },
    });
    break;
  case 'staging':
    //
    Amplify.configure({
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-northeast-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-northeast-1_PivVmkCew',
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: '251vf4v96cgpr1ns4uc587iuml',
      },
      oauth: {
        // 以下、全てCognitoに設定した情報
        domain: 'auth.stg.alligate.net',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://mgr.stg.alligate.net/',
        redirectSignOut: 'https://mgr.stg.alligate.net/',
        responseType: 'code',
      },
    });
    break;
  case 'production':
    //
    Amplify.configure({
      Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
        // REQUIRED - Amazon Cognito Region
        region: 'ap-northeast-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'ap-northeast-1_mrqdRdxGF',
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: '1j8gpm7nnf3undf5s4spr7h238',
      },
      oauth: {
        // 以下、全てCognitoに設定した情報
        domain: 'auth.alligate.net',
        scope: ['email', 'openid'],
        redirectSignIn: 'https://alliboard.alligate.net',
        redirectSignOut: 'https://alliboard.alligate.net',
        responseType: 'code',
      },
    });
    break;
  default:
    break;
}
Vue.use(VueResource);
Vue.use(auth);
export default new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
