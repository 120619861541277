











































declare global {
  interface Window {
    WebAppInterface: any;
    webkit: any;
  }
}

import Vue, { PropType } from 'vue';
import Breadcrumbs, { BreadcrumbItem } from '@/components/molecules/Breadcrumbs/Breadcrumbs.vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';
import UserAddForm from '@/components/organisms/UserAddForm/UserAddForm.vue';
import * as Config from '@/config';
export interface DataType {
  Message: any;
  isMobibleApp: boolean;
}

export default Vue.extend({
  name: 'UserAddTemplate',
  components: {
    Breadcrumbs,
    UserAddForm,
    SuccessButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    gateGroupOptions: {
      type: Array,
      default: () => [],
    },
    skillOptions: {
      type: Array,
      default: () => [],
    },
    groupsAddFail: {
      type: Array,
      default: () => [],
    },
    userData: {
      default: () => ({}),
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isSendEmail: {
      type: Boolean,
      default: false,
    },
    isConfirm: {
      type: Number,
      default: 0,
    },
    errorMessageLogs: {
      type: String,
      default: '',
    },
    successMessageLogs: {
      type: String,
      default: '',
    },
  },
  data: (): DataType => ({
    Message : Config,
    isMobibleApp: false,
  }),
  created() {
    if (window.WebAppInterface !== undefined || window.webkit !== undefined) {
      this.isMobibleApp = true;
    }
  },
  computed: {
    breadcrumbs: {
      get(): BreadcrumbItem[] {
        return [
          { text: 'ユーザー', disabled: true },
          { text: this.isEdit ? 'ユーザー編集' : '新規ユーザー登録', disabled: true },
        ];
      },
    },
  },
  methods: {
  },
});
