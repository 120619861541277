





































export default {
  name: 'DatePicker',
  data: () => ({
    dateS: '',
    dateStart: false,
  }),
};
