const ENV = process.env.VUE_APP_ENV;
// config route API
export const DOMAIN = process.env.VUE_APP_API_ENDPOINT ? process.env.VUE_APP_API_ENDPOINT : '';
export const FEDERATED_LOGIN_SERVICE_API = DOMAIN + ENV + '/federated-login-service';
export const OWNER_FEDERATED_LOGIN_SERVICE_API = DOMAIN + ENV + '/relation/owner-federated-login-service';
export const LOGIN_API = DOMAIN + ENV + '/login';
export const REFRESH_TOKEN_API = DOMAIN + ENV + '/refresh';
export const USER_PROFILE_API = DOMAIN + ENV + '/myprofile';
export const USERS_LIST_API = DOMAIN + ENV + '/user';
export const CALENDAR_LIST_API = DOMAIN + ENV + '/calendar';
export const CALENDARS_LIST_API = DOMAIN + ENV + '/calendars';
export const PLAN_API = DOMAIN + ENV + '/plan';
export const CONTROLLER_COMMAND_API = DOMAIN + ENV + '/controller-command';
export const USER_FEDERATED_LOGIN_SERVICE_API = DOMAIN + ENV + '/relation/user-federated-login-service';
export const LOG_GATE_LIST_API = DOMAIN + ENV + '/log/gate';
export const CARD_LIST_API = DOMAIN + ENV + '/pcode';
export const LOG_OWNER_LIST_API = DOMAIN + ENV + '/log/owner';
export const LOG_USER_OPERATION_LIST_API = DOMAIN + ENV + '/log/user-operation';
export const GROUP_LIST_API = DOMAIN + ENV + '/group';
export const GROUPS_LIST_API = DOMAIN + ENV + '/groups';
export const RELATION_GATE_GROUP = DOMAIN + ENV + '/relation/gate-group';
export const GROUP_PASSAGE = DOMAIN + ENV + '/group/passage';
export const PASSABLE_TIME_SETTING = DOMAIN + ENV + '/passable-time-setting';
export const GROUPS_PASSAGE_API = DOMAIN + ENV + '/group/passage';
export const RELATION_GATE_GROUP_API = DOMAIN + ENV + '/relation/gate-group';
export const PHONES_LIST_API = DOMAIN + ENV + '/phone';
export const GATES_PASSAGE_API = DOMAIN + ENV + '/gate/passage';
export const GATES_LIST_API = DOMAIN + ENV + '/gate';
export const DATE_TYPE = DOMAIN + ENV + '/day-type';
export const UNLOCKING_TIME_SETTING = DOMAIN + ENV + '/unlocking-time-setting';
export const RELATION_CONTROLLER_GATE = DOMAIN + ENV + '/relation/controller-gate';
export const CONTROLLER_API = DOMAIN + ENV + '/controller';
export const CONTROLLER_MANAGEMENT_USER_API = DOMAIN + ENV + '/controller-management-user';
export const CONTROLLER_MODEL_API = DOMAIN + ENV + '/controller/model';
export const CONTROLLER_MANAGEMENT_DEVICE = DOMAIN + ENV + '/controller-management-device';
export const WORKTIME = DOMAIN + ENV + '/worktime';
export const FORGOT_PASSWORD_API = DOMAIN + ENV + '/password/forgot';
export const RESET_PASSWORD_API = DOMAIN + ENV + '/password/reset';
export const USER_CHANGE_PASSWORD_API = DOMAIN + ENV + '/password/change';
export const PUBLIC_USER_API = DOMAIN + ENV + '/public/user';

// config route website
export const LOGIN = '/login';
export const CONFIRM = '/confirm';
export const DASHBOARD = '/dashboard';
export const CARD_LIST = '/cards';
export const CARD_ADD = '/card/add';
export const CARD_EDIT = '/card/edit';
export const CARD_DETAIL = '/card/detail';
export const CARD_IMPORT = '/card/import';
export const SETTING = '/setting';
export const REFLECTION_STATUS = '/reflection-status';
export const NOTICE_LIST = '/notice-list';
export const LOG = '/log';
export const GATE = '/gates';
export const CONFIRM_SETTING = '/confirm-setting';
export const GATE_EDIT = '/gate/edit';
export const GATE_DETAIL = '/gate/detail';
export const GATE_ADD_USER = '/gate/user';
export const GATE_ADD_GROUP = '/gate/gate-group';
export const GATE_ADD_USER_GROUP = '/gate/user-group';
export const GROUP = '/groups';
export const GROUP_DETAIL = '/group/detail';
export const GROUP_ADD = '/group/add';
export const GROUP_EDIT = '/group/edit';
export const GROUP_ADD_GATE = '/group/group-gate';
export const GROUP_ADD_USER = '/group/group-user';
export const PERSONAL_SETTING = '/personal';
export const CONTROL_PANEL = '/control-panel';
export const CONTROL_PANEL_DETAIL = '/control-panel/detail';
export const CONTROL_PANEL_ADD = '/control-panel/add';
export const CONTROL_PANEL_EDIT = '/control-panel/edit';
export const USER_LIST = '/user';
export const USER_ADD = '/user/add';
export const USER_EDIT = '/user/edit';
export const USER_COMPLETE = '/user/complete';
export const USER_ADD_GROUP = '/user/group';
export const TIME_ADD_GROUP = '/setting/group';
export const USER_ADD_GROUP_GATE = '/user/group-gate';
export const USER_DETAIL = '/user/detail';
export const PCODE_LIST_API = DOMAIN + ENV + '/pcode';


export const GOOGLE = 'Google';
export const ONELOGIN = 'Onelogin';
export const APPLEID = 'Apple';

export const OWNER_LOGIN_METHOD_API = DOMAIN + ENV + '/login/method';
export const OWNER_API = DOMAIN + ENV + '/owner';
export const PAGE_DEFAULT = 100;
export const PER_PAGE = 100;
export const TEXT_EDIT = '編集';
export const TEXT_DELETE = '削除';
export const ERROR_400 = '入力したパラメーターが誤っています。入力内容をご確認ください。';
export const ERROR_400_INCORRECT_USER = 'ユーザー名、または、パスワードが間違っている';
export const ERROR_403 = 'アクセス情報が不正です。もう一度やり直してください。';
export const ERROR_404 = '該当するデーターがありません。';
export const ERROR_404_CALENDAR = 'カレンダーの設定に失敗しました。再度やり直してください。';
export const ERROR_400_UNLOCKTIME = '連続解錠時間の更新に失敗しました。再度やり直してください。';
export const ERROR_409_UNLOCKTIME = '既に同じ曜日の連続解錠時間が登録されてます。';
export const ERROR_403_DENIED = 'アクセス情報が不正です。ログインし直してからやり直してください';
export const ERROR_409 = '入力されたユーザーIDは既に使用されています。他のユーザーIDを入力してください。';
export const ERROR_409_USER = '該当するメールアドレス・ユーザーは登録済みです。';
export const ERROR_500 = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
export const ERROR_503 = 'サーバーから応答がありませんでした。しばらく経ってからやり直してください。';
export const ERROR_420 = '登録上限を超えたため通行可能時間帯を登録できません。<br />※登録上限が9件までです。';
export const ERROR_DEFAULT = 'アクセス情報が不正です。もう一度やり直してください。';

export const SERVER_CONNECT_ERROR = 'システム管理者にお問い合わせください。';

// Message
export const ADD_PASSABLE_TIME_API_SUCCESS = '通行可能時間帯設定が追加されました。';
export const EDIT_PASSABLE_TIME_API_SUCCESS = '通行可能時間帯設定が編集されました。';
export const ADD_PCODE_API_SUCCESS = 'カード番号が追加されました。';
export const ADD_USER_API_ERROR = 'エラーが起こりました。システム管理者にお問い合わせください。';
export const ADD_PASSABLE_SUCCESS = 'ADD_PASSABLE_SUCCESS';
export const EDIT_USER_API_SUCCESS = 'ユーザーが編集されました。';
export const ADD_USER_API_SUCCESS = 'ユーザーが追加されました。';
export const ADD_GATE_API_SUCCESS = 'ゲートが追加されました。';
export const ADD_API_ERROR = 'エラーが起こりました。システム管理者にお問い合わせください。';
export const ADD_GROUP_API_SUCCESS = 'グループが追加されました。';
export const EDIT_GROUP_API_SUCCESS = 'グループが編集されました。';
export const EDIT_GATE_API_SUCCESS = 'ゲートが編集されました。';
export const EDIT_PCODE_API_SUCCESS = '個人コードが編集されました。';
export const EDIT_SETTING_API_SUCCESS = '設定が編集されました。';
export const EDIT_PHONE_API_SUCCESS = 'スマートフォンが編集されました。';
export const DELETE_API_SUCCESS = '選択項目が削除されました。';
export const DELETE_API_GROUP_PASSABLE_SUCCESS = '選択項目が削除されました。';
export const EDIT_CONFIG_LOG_API_SUCCESS = '照合ログが編集されました。';
export const DELETE_CONFIG_LOG_API_SUCCESS = '照合ログが初期化しました。';
export const CALENDAR_SUCCESS = 'カレンダーの設定を行いました。';
export const RESET_API_SUCCESS_RESET_PASSWORD = 'パスワードがリセットされました。';
export const ADD_CONTROLER_API_SUCCESS = '制御盤が追加されました。';
export const EDIT_CONTROLER_API_SUCCESS = '制御盤を更新しました。';

export const PCODE_REQUIRED = 'カード番号は必須です。';
export const PCODENAME_REQUIRED = '個人コードは必須です';
export const PCODE_VALIDATE_NFC = 'カード番号は半角英数字を組み合わせた4文字以上20文字以内で入力してください。';
export const PCODE_VALIDATE_NUMERIC = 'テンキーは半角数字を4文字以上20文字以内で入力してください。';
export const USERID_VALIDATE = 'ユーザーIDは半角英数字で4文字以上96文字以内で設定してください。';
export const VALIDATE_GROUP_NAME = 'グループ名は64文字以内で入力してください。';
export const VALIDATE_START_TIME = '入力したパラメーターが誤っています。入力内容をご確認ください。';
export const VALIDATE_END_TIME = '入力したパラメーターが誤っています。入力内容をご確認ください。';
export const VALIDATE_USER_ID = 'ユーザーIDは半角英数字で4文字以上96文字以内で設定してください。';
export const VALIDATE_PASSWORD = 'ログインパスワードは英大文字、英小文字、数字を組み合わせた8文字以上64文字以内で設定してください。';
export const VALIDATE_MODEL = '制御盤モデルを選択してください。';
export const ERROR_ADD_USER_1 = 'ユーザーが 追加されました。ユーザーとグループの紐づけに失敗しています。<br> また、カードの紐づけ、期限付き解錠可能ゲートの登録に失敗しています。<br> ユーザー編集より、再度確認をしてください。<br> 別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME <br />登録失敗ゲート名：GATE_NAME <br />登録失敗カード番号：CARD_NAME <br>';
export const ERROR_EDIT_USER_1 = 'ユーザーが 編集されました。ユーザーとグループの紐づけに失敗しています。<br> また、カードの紐づけ、期限付き解錠可能ゲートの登録に失敗しています。<br> ユーザー編集より、再度確認をしてください。<br> 別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME <br />登録失敗ゲート名：GATE_NAME <br>登録失敗カード番号：CARD_NAME <br>';
export const ERROR_ADD_USER_2 = 'ユーザーが 追加されました。 ユーザーとグループの紐づけに失敗しています。<br> ユーザー編集より、ユーザーとグループの確認をしてください。<br> 別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME';
export const ERROR_EDIT_USER_2 = 'ユーザーが 編集されました。 ユーザーとグループの紐づけに失敗しています。<br> ユーザー編集より、ユーザーとグループの確認をしてください。<br> 別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME';
export const ERROR_ADD_USER_3 = 'ユーザーが 追加されました。<br>すでに解錠可能なゲートな為、期限付き解錠可能ゲートは登録できません<br>登録失敗ゲート名：GATE_NAME';
export const ERROR_EDIT_USER_3 = 'ユーザーが 編集されました。<br>すでに解錠可能なゲートな為、期限付き解錠可能ゲートは登録できません<br>登録失敗ゲート名：GATE_NAME';
export const ERROR_ADD_USER_4 = 'ユーザーが 追加されました。ユーザーとカードの紐づけに失敗しています。<br>ユーザー編集より、カードの確認をしてください。<br>登録失敗カード番号：CARD_NAME';
export const ERROR_EDIT_USER_4 = 'ユーザーが 編集されました。ユーザーとカードの紐づけに失敗しています。<br>ユーザー編集より、カードの確認をしてください。<br> 登録失敗カード番号：CARD_NAME';
export const ERROR_ADD_USER_5 = 'ユーザーが 追加されました。カードの紐づけ、期限付き解錠可能ゲートの登録に失敗しています。<br>ユーザー編集より、再度確認をしてください。<br>別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗ゲート名：GATE_NAME <br />登録失敗カード番号：CARD_NAME ';
export const ERROR_EDIT_USER_5 = 'ユーザーが 編集されました。カードの紐づけ、期限付き解錠可能ゲートの登録に失敗しています。<br>ユーザー編集より、再度確認をしてください。<br>別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗ゲート名：GATE_NAME <br />登録失敗カード番号：CARD_NAME ';
export const ERROR_ADD_USER_6 = 'ユーザーが 追加されました。ユーザーとグループの紐づけに失敗しています。<br>また、カードの紐づけに失敗しています。<br>ユーザー編集より、再度確認をしてください。<br>別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME <br />登録失敗カード番号：CARD_NAME ';
export const ERROR_EDIT_USER_6 = 'ユーザーが 編集されました。ユーザーとグループの紐づけに失敗しています。<br>また、カードの紐づけに失敗しています。<br>ユーザー編集より、再度確認をしてください。<br>別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME <br />登録失敗カード番号：CARD_NAME ';
export const ERROR_ADD_USER_7 = 'ユーザーが 追加されました。ユーザーとグループの紐づけに失敗しています。<br>また、期限付き解錠可能ゲートの登録に失敗しています。<br>ユーザー編集より、再度確認をしてください。<br>別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME <br />登録失敗ゲート名：GATE_NAME ';
export const ERROR_EDIT_USER_7 = 'ユーザーが 編集されました。ユーザーとグループの紐づけに失敗しています。<br>また、期限付き解錠可能ゲートの登録に失敗しています。<br>ユーザー編集より、再度確認をしてください。<br>別の通行可能時間帯が設定されているグループが複数ある場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME <br />登録失敗ゲート名：GATE_NAME ';
export const ERROR_GATE_EDIT = ' ゲートが編集されました。<br> グループとゲートの紐づけに失敗しています。<br>別の通行可能時間帯が設定されているグループを複数紐づけている場合、追加することができません。<br>登録失敗グループ名：GROUP_NAME <br>';

export const WEBAPP_LOGIN_ENDPOINT = process.env.VUE_APP_LOGIN_ENDPOINT ? process.env.VUE_APP_LOGIN_ENDPOINT : '';
