var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "info-item" },
    [
      _c(
        "v-row",
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-col",
                { attrs: { cols: "4" } },
                [_c("v-subheader", [_vm._v(_vm._s(_vm.title))])],
                1
              )
            : _c(
                "v-col",
                { attrs: { cols: "5" } },
                [
                  _c("v-subheader", { staticClass: "ml-0 pa-0" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ],
                1
              ),
          _c(
            "v-col",
            { staticClass: "text-body-2", attrs: { "align-self": "center" } },
            [_vm._v(" " + _vm._s(_vm.text) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }