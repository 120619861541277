var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login-layout blur-login" },
    [
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.loading, color: "white" } },
        [
          _c("v-progress-circular", {
            staticClass: "user-loading-progress",
            attrs: { indeterminate: "", color: "green" }
          })
        ],
        1
      ),
      _c(
        "v-container",
        {
          style: [
            _vm.step === 1 || _vm.step === 3
              ? { "max-width": "600px" }
              : { "max-width": "780px" }
          ]
        },
        [
          _c(
            "v-card",
            { staticClass: "background-green" },
            [
              _vm.step === 0
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmit", {
                            ownerId: _vm.credentials.ownerId,
                            username: _vm.username,
                            password: _vm.password
                          })
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "background-white ",
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    "align-self": "center"
                                  }
                                },
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "logo-text mt-10 mb-10"
                                        },
                                        [
                                          !_vm.$vuetify.breakpoint.xs
                                            ? _c("v-img", {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  src: require("../../../assets/image/Quintbridge.svg"),
                                                  "max-height": "100%",
                                                  "max-width": "230px"
                                                }
                                              })
                                            : _c("v-img", {
                                                attrs: {
                                                  src: require("../../../assets/image/Quintbridge.svg"),
                                                  "max-height": "100%",
                                                  "max-width": "150px",
                                                  width:
                                                    _vm.$vuetify.breakpoint
                                                      .width - 150
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "text-color-white text-bold input-login",
                                              attrs: {
                                                block: "",
                                                type: "button",
                                                color: "black",
                                                elevation: "1",
                                                disabled: _vm.disabled
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "clickAddUser"
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" 新規登録 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "allig-text-link text-center col-8 mb-1 not-link"
                                        },
                                        [
                                          _vm._v(
                                            " 登録情報の変更はログインを行ってください "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "input-full",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            class:
                                              _vm.errorMessage.userId !== ""
                                                ? "input-login input-error"
                                                : "input-login",
                                            attrs: {
                                              autocomplete: "off",
                                              "prepend-inner-icon":
                                                "mdi-account-circle",
                                              color: "green",
                                              "hide-details": "auto",
                                              "single-line": "",
                                              outlined: "",
                                              dense: "",
                                              label: "会員ID "
                                            },
                                            model: {
                                              value: _vm.username,
                                              callback: function($$v) {
                                                _vm.username = $$v
                                              },
                                              expression: "username"
                                            }
                                          }),
                                          _vm.errorMessage.userId !== ""
                                            ? _c(
                                                "p",
                                                { staticClass: "txt-error" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errorMessage.userId
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "input-full",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            class:
                                              _vm.errorMessage.password !== ""
                                                ? "input-login input-error"
                                                : "input-login",
                                            attrs: {
                                              "prepend-inner-icon": "mdi-lock",
                                              "append-icon": _vm.show1
                                                ? "mdi-eye"
                                                : "mdi-eye-off",
                                              type: _vm.show1
                                                ? "text"
                                                : "password",
                                              label: "パスワード",
                                              color: "green",
                                              "hide-details": "auto",
                                              "single-line": "",
                                              outlined: "",
                                              dense: "",
                                              disabled: _vm.loading,
                                              autocomplete: "new-password"
                                            },
                                            on: {
                                              "click:append": function($event) {
                                                _vm.show1 = !_vm.show1
                                              }
                                            },
                                            model: {
                                              value: _vm.password,
                                              callback: function($$v) {
                                                _vm.password = $$v
                                              },
                                              expression: "password"
                                            }
                                          }),
                                          _vm.errorMessage.password !== ""
                                            ? _c(
                                                "p",
                                                { staticClass: "txt-error" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errorMessage.password
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-0",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "text-color-white text-bold input-login",
                                              attrs: {
                                                block: "",
                                                type: "submit",
                                                color: "black",
                                                elevation: "1",
                                                disabled: _vm.disabled
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit("signIn", {
                                                    ownerId:
                                                      _vm.credentials.ownerId,
                                                    username: _vm.username,
                                                    password: _vm.password
                                                  })
                                                }
                                              }
                                            },
                                            [_vm._v(" ログイン ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2 mt-0",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "allig-text-link text-center col-12 mt-0 pt-0",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "forgot-password"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" パスワードがわからない場合 ")]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2 mt-0",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "allig-text-link text-center col-12 mt-0 pt-0 not-link"
                                        },
                                        [
                                          _vm._v(
                                            " 入館の際に必要なQUINTBRIDGE GATEアプリをダウンロード "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://play.google.com/store/apps/details?id=jp.co.nttwest.quintbridgegate",
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "ml-2",
                                            attrs: {
                                              src: require("../../../assets/image/badge_google.png"),
                                              height: "66px"
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://apps.apple.com/jp/app/quintbridge-gate/id1603780477",
                                            target: "_blank"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "ml-2",
                                            attrs: {
                                              src: require("../../../assets/image/badge_apple.png"),
                                              height: "66px"
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "allig-text-link text-right col-12 not-link"
                                        },
                                        [
                                          _vm._v(
                                            " 「QUINTBRIDGE」は西日本電信電話株式会社の商標です "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 1
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("next", _vm.credentials.ownerId)
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12 mr-12",
                              attrs: { "align-self": "center" }
                            },
                            [
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/Quintbridge.svg"),
                                            "max-height": "100%",
                                            "max-width": "230px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/Quintbridge.svg"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8", sm: "8", md: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.ownerId !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "事業者ID",
                                          autocomplete: "off"
                                        },
                                        model: {
                                          value: _vm.credentials.ownerId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "ownerId",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.ownerId"
                                        }
                                      }),
                                      _vm.errorMessage.ownerId !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.ownerId)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8", sm: "8", md: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "submit",
                                            color: "black",
                                            elevation: "2",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.$emit(
                                                "next",
                                                _vm.credentials.ownerId
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" 次へ ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 3
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("confirmSignin", {
                            mfaCode: _vm.credentials.mfaCode
                          })
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ml-12 mr-12",
                              attrs: { "align-self": "center" }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1", sm: "1", md: "1" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-color-white text-bold input-login",
                                      attrs: {
                                        block: "",
                                        type: "button",
                                        color: "black",
                                        elevation: "1"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.PageLogin()
                                        }
                                      }
                                    },
                                    [_vm._v(" 戻る ")]
                                  )
                                ],
                                1
                              ),
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/Quintbridge.svg"),
                                            "max-height": "100%",
                                            "max-width": "230px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/Quintbridge.svg"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _c(
                                "v-row",
                                {
                                  staticClass: "input-full",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8", sm: "8", md: "8" } },
                                    [
                                      _c("v-text-field", {
                                        class:
                                          _vm.errorMessage.mfaCode !== ""
                                            ? "input-login input-error"
                                            : "input-login",
                                        attrs: {
                                          "prepend-inner-icon": "mdi-account",
                                          color: "green",
                                          "hide-details": "auto",
                                          "single-line": "",
                                          outlined: "",
                                          dense: "",
                                          label: "認証コード",
                                          autocomplete: "off"
                                        },
                                        model: {
                                          value: _vm.credentials.mfaCode,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.credentials,
                                              "mfaCode",
                                              $$v
                                            )
                                          },
                                          expression: "credentials.mfaCode"
                                        }
                                      }),
                                      _vm.errorMessage.mfaCode !== ""
                                        ? _c(
                                            "p",
                                            { staticClass: "txt-error" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.errorMessage.mfaCode)
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mb-2",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "8", sm: "8", md: "8" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "button",
                                            color: "black",
                                            elevation: "2",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "confirmSignin",
                                                {
                                                  mfaCode:
                                                    _vm.credentials.mfaCode
                                                }
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" 次へ ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 4
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmitForgot", {
                            ownerId: _vm.credentials.ownerId,
                            username: _vm.username
                          })
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "background-white ",
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    "align-self": "center"
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1", sm: "1", md: "1" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "button",
                                            color: "black",
                                            elevation: "1",
                                            disabled: _vm.disabled
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.PageLogin()
                                            }
                                          }
                                        },
                                        [_vm._v(" 戻る ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "logo-text mt-10 mb-10"
                                        },
                                        [
                                          !_vm.$vuetify.breakpoint.xs
                                            ? _c("v-img", {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  src: require("../../../assets/image/Quintbridge.svg"),
                                                  "max-height": "100%",
                                                  "max-width": "230px"
                                                }
                                              })
                                            : _c("v-img", {
                                                attrs: {
                                                  src: require("../../../assets/image/Quintbridge.svg"),
                                                  "max-height": "100%",
                                                  "max-width": "150px",
                                                  width:
                                                    _vm.$vuetify.breakpoint
                                                      .width - 150
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "input-full",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            class:
                                              _vm.errorMessage.userId !== ""
                                                ? "input-login input-error"
                                                : "input-login",
                                            attrs: {
                                              "prepend-inner-icon":
                                                "mdi-account-circle",
                                              color: "green",
                                              "hide-details": "auto",
                                              "single-line": "",
                                              outlined: "",
                                              dense: "",
                                              label: "会員ID "
                                            },
                                            model: {
                                              value: _vm.username,
                                              callback: function($$v) {
                                                _vm.username = $$v
                                              },
                                              expression: "username"
                                            }
                                          }),
                                          _vm.errorMessage.userId !== ""
                                            ? _c(
                                                "p",
                                                { staticClass: "txt-error" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errorMessage.userId
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "10",
                                            sm: "8",
                                            md: "8"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "text-color-white text-bold input-login",
                                              attrs: {
                                                block: "",
                                                type: "submit",
                                                color: "black",
                                                elevation: "1",
                                                disabled: _vm.disabled
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit("forgot", {
                                                    ownerId:
                                                      _vm.credentials.ownerId,
                                                    username: _vm.username
                                                  })
                                                }
                                              }
                                            },
                                            [_vm._v(" 認証コードの送信 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "allig-text-link text-center col-8 txt-error not-link"
                                        },
                                        [
                                          _vm._v(
                                            " 「認証コードの送信」をすると登録されているメールアドレスに認証コードが送信されます "
                                          ),
                                          _c("br"),
                                          _vm._v(" ※ "),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "mailto",
                                              attrs: {
                                                href:
                                                  "mailto:noreply@alligate.net"
                                              }
                                            },
                                            [_vm._v("noreply@alligate.net")]
                                          ),
                                          _vm._v(
                                            "からメールが送信されます。ドメイン等で受信拒否設定等をしている場合は、 こちらのメールアドレスからメールを受信できるように設定変更をお願いします "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 5
                ? _c(
                    "v-form",
                    {
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.$emit("formSubmitForgot", {
                            credentials: _vm.credentials
                          })
                        }
                      },
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { staticClass: "formSubmitForgot" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "background-white ",
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    "align-self": "center"
                                  }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "1", sm: "1", md: "1" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-color-white text-bold input-login",
                                          attrs: {
                                            block: "",
                                            type: "button",
                                            color: "black",
                                            elevation: "1",
                                            disabled: _vm.disabled
                                          },
                                          on: { click: _vm.PageForgot }
                                        },
                                        [_vm._v(" 戻る ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { justify: "center" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "logo-text mt-10 mb-10"
                                        },
                                        [
                                          !_vm.$vuetify.breakpoint.xs
                                            ? _c("v-img", {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  src: require("../../../assets/image/Quintbridge.svg"),
                                                  "max-height": "100%",
                                                  "max-width": "230px"
                                                }
                                              })
                                            : _c("v-img", {
                                                attrs: {
                                                  src: require("../../../assets/image/Quintbridge.svg"),
                                                  "max-height": "100%",
                                                  "max-width": "150px",
                                                  width:
                                                    _vm.$vuetify.breakpoint
                                                      .width - 150
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _vm.error !== ""
                                    ? _c(
                                        "v-row",
                                        {
                                          staticClass: "mb-2",
                                          attrs: { justify: "center" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-center col-8 txt-error"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.error) + " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "input-full",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            class:
                                              _vm.errorMessage.userId !== ""
                                                ? "input-login input-error"
                                                : "input-login",
                                            attrs: {
                                              "prepend-inner-icon":
                                                "mdi-account-circle",
                                              color: "green",
                                              "hide-details": "auto",
                                              "single-line": "",
                                              outlined: "",
                                              dense: "",
                                              label: "会員ID ",
                                              autocomplete: "off",
                                              disabled: ""
                                            },
                                            model: {
                                              value: _vm.credentials.username,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.credentials,
                                                  "username",
                                                  $$v
                                                )
                                              },
                                              expression: "credentials.username"
                                            }
                                          }),
                                          _vm.errorMessage.userId !== ""
                                            ? _c(
                                                "p",
                                                { staticClass: "txt-error" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errorMessage.userId
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "input-full",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            class:
                                              _vm.errorMessage.resetCode !== ""
                                                ? "input-login input-error"
                                                : "input-login",
                                            attrs: {
                                              "prepend-inner-icon":
                                                "mdi-account-circle",
                                              color: "green",
                                              "hide-details": "auto",
                                              "single-line": "",
                                              outlined: "",
                                              dense: "",
                                              label: "リセットコードを入力",
                                              type: "text",
                                              autocomplete: "off"
                                            },
                                            model: {
                                              value: _vm.credentials.resetCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.credentials,
                                                  "resetCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "credentials.resetCode"
                                            }
                                          }),
                                          _vm.errorMessage.resetCode !== ""
                                            ? _c(
                                                "p",
                                                { staticClass: "txt-error" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errorMessage.resetCode
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "allig-text-link not-link"
                                            },
                                            [
                                              _vm._v(
                                                "※メールにてリセットコードが通知されます"
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "allig-text-link text-center col-8 txt-error not-link"
                                        },
                                        [
                                          _vm._v(
                                            " パスワードは英大文字、英小文字、数字を組み合わせた8文字以上に設定してください "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "input-full",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            class:
                                              _vm.errorMessage.password !== ""
                                                ? "input-login input-error"
                                                : "input-login",
                                            attrs: {
                                              "prepend-inner-icon": "mdi-lock",
                                              "append-icon": _vm.show2
                                                ? "mdi-eye"
                                                : "mdi-eye-off",
                                              type: _vm.show2
                                                ? "text"
                                                : "password",
                                              color: "green",
                                              "hide-details": "auto",
                                              "single-line": "",
                                              outlined: "",
                                              dense: "",
                                              label: "パスワード",
                                              autocomplete: "new-password"
                                            },
                                            on: {
                                              "click:append": function($event) {
                                                _vm.show2 = !_vm.show2
                                              }
                                            },
                                            model: {
                                              value: _vm.credentials.password,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.credentials,
                                                  "password",
                                                  $$v
                                                )
                                              },
                                              expression: "credentials.password"
                                            }
                                          }),
                                          _vm.errorMessage.password !== ""
                                            ? _c(
                                                "p",
                                                { staticClass: "txt-error" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errorMessage.password
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "input-full",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            class:
                                              _vm.errorMessage
                                                .confirmPassword !== ""
                                                ? "input-login input-error"
                                                : "input-login",
                                            attrs: {
                                              "prepend-inner-icon": "mdi-lock",
                                              "append-icon": _vm.show3
                                                ? "mdi-eye"
                                                : "mdi-eye-off",
                                              type: _vm.show3
                                                ? "text"
                                                : "password",
                                              color: "green",
                                              "hide-details": "auto",
                                              "single-line": "",
                                              outlined: "",
                                              dense: "",
                                              label: "再記入新しいパスワード",
                                              autocomplete: "new-password"
                                            },
                                            on: {
                                              "click:append": function($event) {
                                                _vm.show3 = !_vm.show3
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.credentials.confirmPassword,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.credentials,
                                                  "confirmPassword",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "credentials.confirmPassword"
                                            }
                                          }),
                                          _vm.errorMessage.confirmPassword !==
                                          ""
                                            ? _c(
                                                "p",
                                                { staticClass: "txt-error" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.errorMessage
                                                        .confirmPassword
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "mb-2",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "8", sm: "8", md: "8" }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "text-color-white text-bold input-login",
                                              attrs: {
                                                block: "",
                                                type: "submit",
                                                color: "black",
                                                elevation: "1",
                                                disabled: _vm.disabled
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "resetPass",
                                                    {
                                                      credentials:
                                                        _vm.credentials
                                                    }
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" 送信 ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.step === 6
                ? _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.formLogin,
                        callback: function($$v) {
                          _vm.formLogin = $$v
                        },
                        expression: "formLogin"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "background-white ",
                              attrs: {
                                cols: "12",
                                sm: "12",
                                md: "12",
                                "align-self": "center"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1", sm: "1", md: "1" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "text-color-white text-bold input-login",
                                      attrs: {
                                        block: "",
                                        type: "button",
                                        color: "black",
                                        elevation: "1",
                                        disabled: _vm.disabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.PageLogin()
                                        }
                                      }
                                    },
                                    [_vm._v(" 戻る ")]
                                  )
                                ],
                                1
                              ),
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _c(
                                  "div",
                                  { staticClass: "logo-text mt-10 mb-10" },
                                  [
                                    !_vm.$vuetify.breakpoint.xs
                                      ? _c("v-img", {
                                          staticClass: "ml-2",
                                          attrs: {
                                            src: require("../../../assets/image/Quintbridge.svg"),
                                            "max-height": "100%",
                                            "max-width": "230px"
                                          }
                                        })
                                      : _c("v-img", {
                                          attrs: {
                                            src: require("../../../assets/image/Quintbridge.svg"),
                                            "max-height": "100%",
                                            "max-width": "150px",
                                            width:
                                              _vm.$vuetify.breakpoint.width -
                                              150
                                          }
                                        })
                                  ],
                                  1
                                )
                              ]),
                              _c("v-row", { attrs: { justify: "center" } }, [
                                _vm.message
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "alert alert-success txt-success mb-10"
                                      },
                                      [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                    )
                                  : _vm._e()
                              ]),
                              _c("p")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }