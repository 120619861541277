var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "allig-users-template" },
    [
      _c(
        "v-container",
        { staticClass: "container-users" },
        [
          _c("v-row", { staticClass: "ml-0", attrs: { justify: "start" } }),
          _vm.loading === false
            ? _c(
                "v-row",
                { staticClass: "mt-5", attrs: { justify: "start" } },
                [
                  _vm.errorMessageLogs !== ""
                    ? _c(
                        "v-col",
                        {
                          class:
                            "mb-4 font-weight-bold mt-1 bg-error is-mobile-" +
                            _vm.isMobibleApp
                        },
                        [_vm._v(" " + _vm._s(_vm.errorMessageLogs) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("v-spacer", { staticClass: "mt-10" }),
          _c("UserAddForm", {
            attrs: {
              isEdit: _vm.isEdit,
              loading: _vm.loading,
              isConfirm: _vm.isConfirm,
              gateGroupOptions: _vm.gateGroupOptions,
              skillOptions: _vm.skillOptions,
              userInfo: _vm.userData,
              isSendEmail: _vm.isSendEmail,
              successMessageLogs: _vm.successMessageLogs,
              errorMessageLogs: _vm.errorMessageLogs
            },
            on: {
              changeIsSendEmail: function($event) {
                return _vm.$emit("changeIsSendEmail", $event)
              },
              clickSaveUser: function($event) {
                return _vm.$emit("clickSaveUser", $event)
              },
              clickUserForm: function($event) {
                return _vm.$emit("clickUserForm", $event)
              },
              clickConfirm: function($event) {
                return _vm.$emit("clickConfirm", $event)
              },
              clickUpdateUser: function($event) {
                return _vm.$emit("clickUpdateUser", $event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }