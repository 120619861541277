

































import Vue from 'vue';
import AlligHeader from '@/components/organisms/Header/Header.vue';
import SideNavigation from '@/components/organisms/SideNavigation/SideNavigation.vue';

declare global {
  interface Window {
    WebAppInterface: any;
    webkit: any;
  }
}

export interface DataType {
  showNav: boolean;
  loading: boolean;
  activeName: string;
  userName: string;
  isMobibleApp: boolean;
}

export default Vue.extend({
  name: 'LayoutTemplate',
  components: {
    AlligHeader,
    SideNavigation,
  },
  props: {
    pathName: {
      type: String,
      default: 'setting',
    },
    selectedItemNav: {
      type: Number,
      default: 0,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data: (): DataType => ({
    showNav: true,
    activeName: 'setting',
    loading: false,
    userName: '',
    isMobibleApp: false,
  }),
  created() {
    if (window.WebAppInterface === undefined && window.webkit === undefined) {
      this.userName = localStorage.userProfileId;
      this.isMobibleApp = false;
    } else {
      this.isMobibleApp = true;
    }
    const path = this.$route.path.replace('/', '');
    const arr = path.split('/');
    this.menuActive(arr[0]);
    if (this.$vuetify.breakpoint.smAndDown) {
      this.showNav = false;
    }
  },
  watch : {
    pathName(newVal, oldVal) {
      this.activeName = newVal;
      this.menuActive(this.activeName);
    },
  },
  methods: {
    Loading(value: any) {
      this.loading = value;
      if (this.isMobibleApp) {
        const elm = document.getElementById('app');
        if (value) {
          if (elm) {
            const heightWindow = window.innerHeight;
            elm.style.height = heightWindow + 'px';
            elm.style.overflow = 'hidden';
          }
        } else {
          if (elm) {
            elm.style.height = 'auto';
          }
        }
      }
    },
    menuActive(activeName: any) {
      console.log(activeName);
      let selectedItemNav = 1;
      switch (activeName) {
        case 'user' : selectedItemNav = 1; break;
        case 'groups' :
        case 'group' :
        selectedItemNav = 2; break;
        case 'cards' :
        case 'card' :
        selectedItemNav = 3; break;
        case 'gates' :
        case 'gate' :
        selectedItemNav = 4; break;
        case 'log' : selectedItemNav = 5; break;
        case 'setting' : selectedItemNav = 6; break;
        case 'reflection-status' : selectedItemNav = 7; break;
        default: selectedItemNav = 0; break;
      }
      localStorage.setItem('selectedItemNav', selectedItemNav.toString());
    },
  },
});
