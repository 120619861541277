var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "log"
    ? _c("div", [
        _vm.perPage >= 0 && _vm.page >= 0 && _vm.itemsLength > 0
          ? _c(
              "div",
              {
                staticClass:
                  "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
              },
              [
                _vm._v(
                  " 全" +
                    _vm._s(_vm.itemsLength) +
                    "件中 1-" +
                    _vm._s(Math.min(_vm.perPage, _vm.itemsLength)) +
                    "を表示 "
                )
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
              },
              [_vm._v(" 全0件中 0-0を表示 ")]
            )
      ])
    : _c("div", [
        _vm.perPage >= 0 && _vm.page >= 0 && _vm.itemsLength > 0
          ? _c(
              "div",
              {
                staticClass:
                  "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
              },
              [
                _vm._v(
                  " 全" +
                    _vm._s(_vm.itemsLength) +
                    "件中 " +
                    _vm._s((_vm.page - 1) * _vm.perPage + 1) +
                    "-" +
                    _vm._s(Math.min(_vm.page * _vm.perPage, _vm.itemsLength)) +
                    "を表示 "
                )
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "allig-show-per-page text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"
              },
              [_vm._v(" 全0件中 0-0を表示 ")]
            )
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }