




































































import Vue, { PropType } from 'vue';
import SuccessButton from '@/components/molecules/SuccessButton/SuccessButton.vue';

export interface SearchTargetItem {
  label: string;
  value: string;
  isDisabled?: boolean;
}

export interface DataType {
  text: string;
  showCustomSearch: boolean;
  targets: any;
}

export default Vue.extend({
  name: 'Searchbox',
  components: {
    SuccessButton,
  },
  props: {
    searchTargetItems: {
      type: Array as PropType<SearchTargetItem[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    goBack: {
      type: String,
      default: '',
    },
  },
  data(): DataType {
    return {
      text: '',
      showCustomSearch: false,
      targets: [],
    };
  },
  watch: {
    goBack(value: any) {
      if (value !== '') {
        if (typeof localStorage.gateName !== undefined && localStorage.gateName !== 'undefined' &&
        localStorage.gateName !== '' && this.goBack === '/gates') {
          this.text = localStorage.gateName;
        }
        if (typeof localStorage.userName !== undefined && localStorage.userName !== 'undefined' &&
        localStorage.userName !== '' && this.goBack === '/user') {
          this.text = localStorage.userName;
        }
      }
    },
  },
  computed: {
    // targets: {
    //   get(): string[] {
    //     return this.searchTargetItems.map((item) => item.value);
    //   },
    //   set(): void {
    //     return;
    //   },
    // },
  },
  mounted() {
    this.targets = this.searchTargetItems.map((item) => item.value);
    // console.log('goBack', this.goBack);
  },
  methods: {
    onClickOutsideCustomSearch() {
      if (this.showCustomSearch) {
        this.showCustomSearch = false;
      }
    },
    included() {
      return [document.querySelector('.included')];
    },
    search(event: any) {
      if (!this.disabled && event.keyCode === 13) {
        this.$emit('search', {
          text: this.text,
          targets: this.targets,
        });
      }
    },
  },
});
