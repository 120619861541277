var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-overlay",
        {
          class: "is-mobile-overlay-" + _vm.isMobibleApp,
          attrs: { value: _vm.loading, color: "white" }
        },
        [
          _c("v-progress-circular", {
            staticClass: "user-loading-progress",
            attrs: { indeterminate: "", color: "green" }
          })
        ],
        1
      ),
      _vm.isMobibleApp === false
        ? _c("allig-header", {
            attrs: { userName: _vm.userName },
            on: {
              "click-nav": function() {
                return (_vm.showNav = !_vm.showNav)
              },
              "click-guid": function($event) {
                return _vm.$emit("click-guide")
              },
              "click-notice": function($event) {
                return _vm.$emit("click-notice")
              },
              "click-user-setting": function($event) {
                return _vm.$emit("click-user-setting")
              },
              "click-logout": function($event) {
                return _vm.$emit("click-logout")
              }
            }
          })
        : _vm._e(),
      _c(
        "v-main",
        {
          staticClass: "allig-main",
          class: _vm.activeName + " is-mobile-" + _vm.isMobibleApp
        },
        [
          _c(
            "v-container",
            { staticClass: "allig-container", attrs: { fluid: "" } },
            [
              _c("router-view", {
                attrs: { isAdmin: _vm.isAdmin },
                on: {
                  loading: function($event) {
                    return _vm.Loading($event)
                  },
                  activeMenu: function($event) {
                    return _vm.$emit("activeMenu", $event)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }