var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Login", {
    attrs: {
      loginProcess: _vm.loginProcess,
      googleInfo: _vm.googleLoginInfo,
      appleInfo: _vm.appleLoginInfo,
      errorMessage: _vm.errorMessage,
      loading: _vm.loading,
      error: _vm.error,
      message: _vm.message,
      oneLoginInfo: _vm.oneloginLoginInfo
    },
    on: {
      next: _vm.next,
      signInWithIdentityProvider: _vm.signInWithIdentityProvider,
      signIn: function($event) {
        return _vm.signIn($event)
      },
      clickAddUser: function($event) {
        return _vm.clickAddUser($event)
      },
      resetPass: function($event) {
        return _vm.resetPass($event)
      },
      forgot: function($event) {
        return _vm.forgot($event)
      },
      formSubmit: function($event) {
        return _vm.formSubmit($event)
      },
      formSubmitForgot: function($event) {
        return _vm.formSubmitForgot($event)
      },
      goPageLogin: function($event) {
        return _vm.goPageLogin()
      },
      confirmSignin: function($event) {
        return _vm.confirmSignin($event)
      },
      "forgot-password": function($event) {
        return _vm.forgotPassword($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }