













import {Vue } from 'vue-property-decorator';
import LayoutTemplate from '@/components/templates/LayoutTemplate/LayoutTemplate.vue';
import auth from '@/auth';
import { PERSONAL_SETTING } from '@/config';
import * as Config from '@/config';
export default Vue.extend({
  name: 'LayoutTemplatePage',
  components: {
    LayoutTemplate,
  },
  data() {
    return {
      pathName : '',
      isAdmin : false,
      selectedItemNav: 0,
    };
  },
  created() {
    if (localStorage.userProfileId) {
      this.getProfile();
    }
  },
  mounted() {
    if (typeof localStorage.selectedItemNav === 'undefined') {
      localStorage.setItem('selectedItemNav', '0');
      this.selectedItemNav = 0;
    } else {
      this.selectedItemNav = parseInt(localStorage.selectedItemNav);
    }
  },
  methods: {
    activeMenu(menuName: any) {
      switch (menuName) {
        case 'user' : this.selectedItemNav = 1; break;
        case 'groups' : this.selectedItemNav = 2; break;
        case 'cards' : this.selectedItemNav = 3; break;
        case 'gates' : this.selectedItemNav = 4; break;
        case 'log' : this.selectedItemNav = 5; break;
        case 'setting' : this.selectedItemNav = 6; break;
        case 'reflection-status' : this.selectedItemNav = 7; break;
        default: this.selectedItemNav = 0; break;
      }
      this.pathName = menuName;
      localStorage.setItem('selectedItemNav', this.selectedItemNav.toString());
    },
    menuClick(menuName: string) {
      this.pathName = menuName;
      const pathCurrent = `/${menuName}`;
      switch (menuName) {
        case 'user' : this.selectedItemNav = 1; break;
        case 'groups' : this.selectedItemNav = 2; break;
        case 'cards' : this.selectedItemNav = 3; break;
        case 'gates' : this.selectedItemNav = 4; break;
        case 'log' : this.selectedItemNav = 5; break;
        case 'setting' : this.selectedItemNav = 6; break;
        case 'reflection-status' : this.selectedItemNav = 7; break;
        default: this.selectedItemNav = 0; break;
      }
      localStorage.setItem('selectedItemNav', this.selectedItemNav.toString());
      if (this.$route.path !== pathCurrent) {
        this.$router.push({ path: pathCurrent});
      } else {
        this.$router.go(0);
      }
    },
    logout(): void {
      auth.logout();
    },
    personal(): void {
      const url = '/user/edit?userId=' + localStorage.userProfileId + '&goBack=personal';
      if (url !== this.$route.fullPath && this.$route.fullPath !== '/personal') {
        localStorage.setItem('goBack', this.$route.fullPath);
      }
      this.$router.push({ path: PERSONAL_SETTING});
    },
    clickNotice(): void {
      window.open('https://support.alligate.me/hc/ja', '_blank');
    },
    getProfile() {
      this.$http.get(Config.USERS_LIST_API + '?userId=' + encodeURIComponent(localStorage.userProfileId))
        .then((response: any) => {
          console.log(response);
          localStorage.setItem('ownerId', response.body.users[0].owner.ownerId);
          localStorage.setItem('isAdmin', response.body.users[0].isAdmin);
          this.isAdmin = response.body.users[0].isAdmin;
          localStorage.setItem('shareKeyBeValid', response.body.users[0].shareKeyBeValid);
          const option = response.body.users[0].option1.replaceAll('<', '').split('>');
          const authority = option[8] !== undefined ? option[8] : '';
          localStorage.setItem('authority', authority);
          console.log(localStorage.isAdmin);
        }).
        then( null, (err: any) => {
          console.log(err);
        });
    },
  },
});
