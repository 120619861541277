







declare global {
  interface Window {
    WebAppInterface: any;
    webkit: any;
  }
}

import Vue from 'vue';
export default Vue.extend({
  name: 'App',

  data: () => ({
    isMobibleApp: false,
  }),
  created() {
    if (window.WebAppInterface !== undefined || window.webkit !== undefined) {
      this.isMobibleApp = true;
    }
  },
});
